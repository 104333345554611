















import { Component, Vue, Prop } from 'vue-property-decorator';
import { IFormField, PDFFormField } from 'client-website-ts-library/types/Forms/FieldTypes';
import { PDFFormSubfieldChangedData } from '@/app_code/Forms';
import { FormValue } from 'client-website-ts-library/types/Forms';

@Component
export default class FormGraphicalPDFTextSubfield extends Vue implements IFormField {
  private value: string | null = null;

  @Prop()
  private readonly parentKey!: string;

  @Prop()
  private readonly def!: PDFFormField;

  private loweredKey = this.def!.Key.toLowerCase();

  getValue(): FormValue {
    return {
      Key: this.def.Key,
      Value: this.value,
    };
  }

  setValue(value: FormValue) {
    if (typeof value.Value === 'string' || value.Value === null) {
      this.value = value.Value;
    }
  }

  get isMultiline(): boolean {
    return this.def.Flags.indexOf('Multiline') !== -1;
  }

  get isDate(): boolean {
    return this.def.Flags.indexOf('AFDate') !== -1 || this.def.Format.indexOf('AFDate') !== -1;
  }

  get isEmail(): boolean {
    return this.loweredKey.indexOf('email') !== -1;
  }

  get isTel(): boolean {
    return this.loweredKey.indexOf('phone') !== -1 || this.loweredKey.indexOf('mobile') !== -1;
  }

  get isCombed(): boolean {
    return this.def.Flags.indexOf('Comb') !== -1;
  }

  get inputType(): string {
    if (this.isDate) return 'date';
    if (this.isEmail) return 'email';
    if (this.isTel) return 'tel';

    return 'text';
  }

  get autocompleteType(): string {
    if (this.isEmail) return 'email';
    if (this.isTel) return 'tel';

    if (this.loweredKey.indexOf('name')) return 'name';

    return 'on';
  }

  handleChanged() {
    this.$emit('change', {
      def: this.def,
      value: this.value,
    } as PDFFormSubfieldChangedData);
  }
}
